<template>
  <div class="mobile-list">

    <div class="mobile-list-top" :style='bg'>
      <div class="modile-list-top-row">
        <div class="modile-list-top-row-flex1">
          <Dropdown trigger="click">
            <a href="javascript:void(0)" style="color: white">
              个人
              <Icon type="ios-arrow-down"></Icon>
            </a>
            <DropdownMenu slot="list">
              <DropdownItem>部门</DropdownItem>
            </DropdownMenu>
          </Dropdown>
        </div>
      </div>
      <div class="modile-list-top-row">
        <div class="modile-list-top-row-flex1" style="text-align: left;margin-left: 20px;">
          <Dropdown trigger="click">
            <a href="javascript:void(0)" style="color: white">
              2021年
              <Icon type="ios-arrow-down"></Icon>
            </a>
            <DropdownMenu slot="list">
              <DropdownItem>2019年</DropdownItem>
              <DropdownItem>2020年</DropdownItem>
              <DropdownItem>2021年</DropdownItem>
              <DropdownItem>2022年</DropdownItem>
              <DropdownItem>2023年</DropdownItem>
            </DropdownMenu>
          </Dropdown>
        </div>
      </div>
      <div class="modile-list-top-row">
        <div class="modile-list-top-row-flex1" style="height:200px;width:100%;" ref="echartpie">
        </div>
      </div>
      <div class="modile-list-top-row1">
        <div class="modile-list-top-row1-flex2" style="border-right: 1px solid white;">
          <div style="height: 20px;  margin-top: 10px;  color: white;font-size: 25px;font-weight: 20;">684.12</div>
          <div style="height: 20px;  margin-top: 10px;  color: white;">已回款（万元）</div>
        </div>
        <div class="modile-list-top-row1-flex2">
          <div style="height: 20px;  margin-top: 10px;  color: white;font-size: 25px;font-weight: 20;">900.11</div>
          <div style="height: 20px;  margin-top: 10px;  color: white;">未回款（万元）</div>
        </div>
      </div>
    </div>

    <div class="mobile-list-content">
      <div style="height:300px;width:100%;" ref="echartBar">
      </div>
      <div class="fill-row flex-row">
        <div class="text-left dark-font-color text-20" style="width:50%;margin-left: 20px;margin-top: 20px;">合同列表</div>
        <div class="text-right grey-font-color" style="width:50%;margin-right: 20px;margin-top: 20px;" @click="more()">更多</div>
      </div>

      <orderScroll ref="scroll" style="heigth:100%" :data="items" :options="options" :loading="loading" :firstPage="firstPage" :nextPage="nextPage">
        <taskbox :datas="items" :columns="columns" @button1_click="button1_click" @button2_click="button2_click" @button3_click="button3_click" />
      </orderScroll>
    </div>

  </div>

</template>

<script>
import bg_img from "@/static/images/bg_Rectangle.png"
export default {

  data: function () {
    return {
      theme1: 'light',
      disabled: true,
      bg: {
        background: `url(${bg_img})`,
        // backgroundSize: '100% 100%'
        flex: '0 0 auto',
      },
      optionpie: {
        tooltip: {
          trigger: 'item',
          formatter: "{a} <br/>{b}: {c} ({d}%)"
        },
        color: ["#27D9C8", "#D8D8D8"],
        title: {
          text: "1584.23",
          left: "center",
          top: "50%",
          textStyle: {
            color: "#F8F8FF",
            fontSize: 30,
            // align: "center",
            textAlign: "center"
          }
        },
        graphic: {
          type: "text",
          left: "center",
          top: "70%",
          style: {
            text: "总金额（万元）",
            textAlign: "center",
            fill: "#F8F8FF",
            fontSize: 10,
            // fontWeight: 800
          }
        },
        series: [
          {
            name: '总用户数',
            type: 'pie',
            // 放大图形
            radius: ['140%', '145%'],
            // 移动下位置  套住50%文字
            center: ['48%', '80%'],
            label: {
              normal: {
                show: false
              }
            },
            // 起始角度，支持范围[0, 360]
            startAngle: 180,
            data: [
              { value: 40, name: 'abc', itemStyle: { color: '#cccccc' } }, // 起始位置
              { value: 30, name: 'asd', itemStyle: { color: '#DC143C' } }, // 红色区域
              { value: 110, name: 'ksdkfn', itemStyle: { color: '#cccccc' } },// 结束位置
              { value: 180, name: 'jlsad', itemStyle: { color: 'transparent' } } // 透明隐藏区域
            ]
          }
        ],
      },
      optionbar: {
        legend: {
          data: ['目标', '已回款', '总金额', '未回款'],
          top: 30,
        },
        tooltip: {
          trigger: 'axis',
          axisPointer: {            // 坐标轴指示器，坐标轴触发有效
            type: 'line'        // 默认为直线，可选为：'line' | 'shadow'
          },
        },
        xAxis: [
          {
            type: 'category',
            data: ['成涛', '徐维鑫', '袁周', '孙晨']
          }
        ],
        yAxis: [
          {
            type: 'value',
            show: false
          }
        ],
        barGap: '10%',/*多个并排柱子设置柱子之间的间距*/
        barCategoryGap: '40%',
        series: [
          {
            name: '已回款',
            type: 'bar',
            color: '#33CCFF',
            data: [210.33, 132.00, 140.00, 111.30],
            label: {
              show: true,
              position: 'insideTop',
              fontWeight: 400,
              fontSize: 10,
            },
          },
          {
            name: '总金额',
            type: 'bar',
            color: '#3333FF',
            stack: 'overlap',//堆叠效果(字符需要统一)
            data: [522.53, 352.50, 386.00, 323.60],
            label: {
              show: true,
              position: 'insideTop'
            },
          },
          {
            name: '目标',
            type: 'bar',
            color: '#aaa',
            stack: 'overlap',//堆叠效果(字符需要统一)
            data: [600.00, 450.00, 550.00, 400.00],
            label: {
              show: true,
              position: 'insideTop'
            },
          },
          {
            name: '未回款',
            type: 'bar',
            color: '#DC143C',
            data: [312.30, 220.50, 246.00, 212.30],

            label: {
              show: true,
              position: 'insideTop'
            },
          },
        ]
      },
      columns: [
        {
          type: "key_rightkey",
          key: "code",
          keyStyle: "color:black",
          rightkey: "stateName",
          rightkeyStyle: "color:red",
        },
        {
          type: "key",
          key: "source",
          keyStyle: "color:black",
        },
        {
          type: "key_righttime",
          key: "username",
          keyStyle: "color:black",
          rightkeytime: "estimatedEndTime",
          rightkeyStyle: "color:black",
        },
        {
          type: "money_rightkey",
          moneykey: "money",
          moneyStyle: "color:red",
          rightkey: "company",
          rightkeyStyle: "color:#999",
        },
        {
          type: "button3",
          buttonname1: "下单",
          buttonname2: "开票",
          buttonname3: "回款",
        },

      ],
      options: {
        pullDownRefresh: {
          threshold: 60,
          stopTime: 500,
          txt: "刷新成功",
        },
        pullUpLoad: true,
      },
      items: [],
      pageIndex: 1,
      pageSize: 10,
      total: 0,
      loading: false,
      hasMore: true,
      showingFilter: false,
      filterOption: {
        startTime: null,
        endTime: null,
        orderStatusKey: 0,
      },
      enterkey: [],
      keyword: "",
      //联动选择器
      pikerOption: [
        {
          value: 'kaifabu',
          text: '开发部',
          children: [
            {
              value: 'yuanzhou',
              text: '袁周 ',
              //children: [{ value: 1, text: 'One' }, { value: 2, text: 'Two' }]
            },
            {
              value: 'xuweixin',
              text: '徐维鑫',

            }
          ]
        },
        {
          value: 'xingzhengbu',
          text: '行政部',
          children: [
            {
              value: 'dujuan',
              text: '杜娟',

            },
            {
              value: 'zhangwei',
              text: '张薇',
            }
          ]
        }
      ],
      picker: [],
    };
  },
  methods: {
    menuclick(name) {
      let url = ""
      if (name == "1") {
        url = "mycard";
      } else if (name == "2") {
        url = "mytask";
      } else if (name == "3") {
        url = "myperformance";
      }
      this.$router.push({
        name: url,
      });
    },
    button1_click(item) {
      console.log(1);
      console.log(item);
      this.showCascadePicker();
    },
    button2_click(item) {
      console.log(2);
      console.log(item);
    },
    button3_click(item) {
      console.log(3);
      console.log(item);
    },
    more(){
      console.log("更多");
    },
    textChanged: function (keywords) {
      this.keyword = keywords;
    },
    filterClicked: function () {
      this.showingFilter = true;
      this.$nextTick(() => {
        this.$refs.searchDrawer.show();
      });
    },
    filterBgClicked: function () {
      this.showingFilter = false;
    },
    resetFilter: function () {
      this.filterOption = {
        startTime: null,
        endTime: null,
        orderStatusKey: 0,
        orderTypeKey: 1,
        orderSourceKey: null,
      };
      this.showingFilter = false;
      this.firstPage();
    },
    confirmFilter: function () {
      this.filterOption = this.$refs.filter.getOption();
      this.showingFilter = false;
      this.firstPage();
    },
    firstPage: function () {
      this.pageIndex = 1;
      this.dataRequest();
    },
    nextPage: function () {
      if (this.loading || !this.hasMore) {
        this.$refs.scroll.forceUpdate(true);
      } else {
        this.dataRequest();
      }
    },
    dataRequest: function () {
      this.items = [
        {
          code: "AAABBCCDD",
          stateName: "进行中",
          source: "协同平台",
          username: "徐维鑫",
          estimatedEndTime: "2010-02-10",
          company: "坤达安",
          money: 22231.3,
        },
        {
          code: "ABCEDEFG",
          stateName: "进行中",
          source: "协同平台",
          username: "吕秋芬",
          estimatedEndTime: "2010-02-10",
          company: "坤达安",
          money: 2223.3,
        },
        {
          code: "ACBCCCDC",
          stateName: "进行中",
          source: "协同平台",
          username: "袁周",
          estimatedEndTime: "2010-02-10",
          company: "坤达安",
          money: 9382,
        },]


    },
    creatCascadePicker() {
      this.picker = this.$createCascadePicker({
        title: '选择',
        data: this.pikerOption,
        selectedIndex: [0, 1, 0],
        onSelect: this.selectHandle,
        onCancel: this.cancelHandle
      });
    },
    showCascadePicker() {
      this.picker.show();
    },
    selectHandle(selectedVal, selectedIndex, selectedText) {
      console.log(selectedVal)
      console.log(selectedIndex)
      console.log(selectedText)

    },
    cancelHandle() {
      this.$createToast({
        type: 'correct',
        txt: '取消',
        time: 1000
      }).show()
    }


  },
  mounted: function () {
    this.$nextTick(function () {
      let pie = this.$refs.echartpie;
      var echartpie = this.$echarts.init(pie);
      echartpie.setOption(this.optionpie);
      let bar = this.$refs.echartBar;
      var echartBar = this.$echarts.init(bar);
      echartBar.setOption(this.optionbar);
    });
    this.firstPage();
    this.creatCascadePicker();
  },
};
</script>

<style lang="scss" scoped>
.mobile-input-disabled {
  .title {
    margin-left: 5px !important;
    padding-left: 6px !important;
    text-align: left;
  }
}
</style>
<style scoped>
.ivu-menu-item-active {
  color: white;
  border-bottom: 2px solid white;
}

.ivu-menu-primary {
  background: none;
}

.page {
  height: 100%;
  width: 100%;
  display: flex;
  flex-direction: column;
}
.top {
  flex: 0 0 30%;
  width: 100%;
  display: flex;
  flex-direction: column;
}
.top_top {
  flex: 0 0 50%;
}
.top_bottom {
  height: 60%;
  width: 100%;
  display: flex;
  flex-direction: row;
  border: none;
  text-align: center;
}
.top_bottom_left {
  flex: 0 0 30%;
  text-align: right;
  display: flex;
  flex-direction: column;
}
.top_bottom_right {
  flex: 0 0 68%;
  text-align: left;
  display: flex;
  flex-direction: column;
}
/* .lable {
  height: 20px;
  margin-top: 10px;
  padding-left: 20px;
  color: white;
} */

.img {
  border-radius: 50%;
  width: 60px;
  height: 60px;
}
</style>  